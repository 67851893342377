<template>
	<div class="topic-detail">
		<search-bar />

		<div class="title" v-show="!loading">#{{ title }}</div>
		<div class="title-topic-2" v-show="!loading">{{ pagination.numFound }}部作品</div>
		<div class="topic-detail-box" v-loading="loading" element-loading-background="#121330">
			<div class="topic-detail-item" v-for="(item, index) in topicList" :key="index">
				<div class="topic-detail-item-left" @click="goDetail(item.videoId)">
					<!-- <img :src="getImg(item.poster, '180', '260')" alt="" /> -->
					<lazy-pic :array="[item.poster, '180', '260']" :type="'poster'"   :isWrapper="'a'"  />
				</div>
				<div class="topic-detail-item-right">
					<div class="topic-detail-item-right-top">
						<img :src="topicIcon" alt="" />
						<div class="topic-detail-item-right-top-text">
							{{ item.intro }}
						</div>
					</div>
					<div class="topic-detail-item-right-bottom">
						<div class="topic-detail-item-right-bottom-title">
							<div class="topic-detail-item-right-bottom-title-left" @click="goDetail(item.videoId)">
								{{ item.title }}
							</div>
							<div class="topic-detail-item-right-bottom-title-right">
								<span style="margin-right:4px;">{{ item.rating.toFixed(1) }}</span
								>/10
							</div>
						</div>
						<div class="topic-detail-item-right-bottom-tags">
							<div
								class="topic-detail-item-right-bottom-tags-item"
								v-for="(tag, tagIndex) in item.tags"
								:key="tagIndex"
							>
								{{ tag.tagName }}
							</div>
						</div>
						<div class="topic-detail-item-right-bottom-actor">
							<span
								v-for="(act, actIndex) in item.directors.slice(0, 1)"
								:key="actIndex"
								class="topic-detail-item-right-bottom-actor-item"
							>
								{{ act.name }}</span
							>
							<span
								v-show="item.directors.length && item.actors.length"
								style="margin-left: 4px;color: #797a8e;"
								>/</span
							>
							<span
								v-for="(act, actIndex) in item.actors.slice(0, 3)"
								:key="actIndex"
								class="topic-detail-item-right-bottom-actor-item"
							>
								{{ act.name }}</span
							>
						</div>
					</div>
				</div>
			</div>
		</div>
		<page-select v-show="topicList.length" :pagination="pagination" @pageChange="pageChange"></page-select>
	</div>
</template>

<script>
import searchBar from "./search-bar"
import pageSelect from "./page-select"
export default {
	name: "topic-detail",
	data() {
		return {
			backgroundImage: require("@assets/index.png"),
			avatarImage: require("@assets/avatar.png"),
			wantIcon: require("@assets/explore/want.png"),
			timeIcon: require("@assets/aside/aside7.png"),
			emptyIcon: require("@assets/explore/empty.png"),
			selectIcon: require("@assets/recommond/select.png"),
			selectIcon2: require("@assets/recommond/select2.png"),
			noworkIcon: require("@assets/detail/empty.png"),
			searchWidth: 0,
			searchPlaceHolder: "复仇者联盟",
			searchVal: "",
			user: {
				name: "胖虎不是铁憨憨",
			},
			topicIcon: require("@assets/topic/title.png"),
			topicList: [],
			loading: true,
			pagination: {
				currentPage: 1,
				pageSize: 0,
				numFound: 0,
				totalPage: 0,
			},
			id: null,
			title: "",
		}
	},
	methods: {
		pageChange(e) {
			console.log('pageChange', e)
			this.pagination.currentPage = e
			this.init()
			console.log(e)
		},
		async init() {
			this.loading = true
			this.topicList = []
			if (this.id) {
				const result = await this.api.getTopicDetail({
					childColumnId: this.id,
					currentPage: this.pagination.currentPage
				})
				this.topicList = result.data.list
				this.title = result.data.columnName
				this.pagination = result.data.pagination
			} else {
				for (let i = 0; i < 10; i++) {
					this.topicList.push({
						thumb: require("@assets/topic/1.png"),
						title: "海绵宝宝",
						rating: "9.5",
						videoId: 1,
						ratingFull: "10",
						intro:
							"维也纳的一家冰淇淋咖啡馆里，薇拉内尔默默地享用面前的甜点，她是一名职业杀手，两年来在十多个国家创造了辉煌的业绩。",

						tags: [
							{ tagName: 2020, tagId: 1 },
							{ tagName: "日本", tagId: 1 },
							{ tagName: "动画", tagId: 1 },
						],
						actors: [
							{ peopleId: 1, name: "宫崎骏 " },
							{ peopleId: 1, name: "倍赏千惠子" },
							{ peopleId: 1, name: " 美轮明宏" },
							{ peopleId: 1, name: "克里斯" },
						],
					})
				}
			}
			this.loading = false
		},
	},
	watch: {
		"$store.state.hotJump": function(newVal, oldVal) {
			console.log("我是watch来的数据", newVal)
			this.id = newVal.channelId
			this.init()
		},
	},
	mounted() {
		console.log(this.$route.query)
		this.id = this.$route.query.id
		this.init()
	},
	components: {
		searchBar,
		pageSelect,
	},
}
</script>

<style lang="scss">
.topic-detail {
	background: #121330;
	width: calc(100% - 100px);
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	position: relative;
	padding: 0 50px;
	.title {
		font-size: 30px;
		font-family: PingFang SC;
		font-weight: 600;
		line-height: 42px;
		color: #ffffff;
		margin-top: 80px;
		user-select: none;
	}
	.title-topic-2 {
		font-size: 15px;
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 21px;
		color: rgba(255, 255, 255, 0.5);
		margin-top: 10px;
	}
	.topic-detail-box {
		margin-top: 88px;
		min-height: 250px;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		flex-wrap: wrap;

		.topic-detail-item {
			width: 48%;
			display: flex;
			justify-content: center;
			align-items: flex-start;
			margin-bottom: 40px;
			&:nth-child(even) {
				margin-right: 2%;
			}
			.topic-detail-item-left {
				border-radius: 10px;
				overflow: hidden;
				flex-shrink: 0;
				width: 180px;
				height: 260px;
				img {
					width: 180px;
					height: 260px;
					cursor: pointer;
				}
			}
			.topic-detail-item-right {
				min-height: 260px;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				width:100%;
				.topic-detail-item-right-top {
					height: 90px;
					padding: 10px 25px;
					position: relative;

					img {
						width: 25%;
						position: absolute;
						top: 0;
						left: 10px;
					}
					.topic-detail-item-right-top-text {
						font-size: 15px;
						font-family: PingFang SC;
						font-weight: 400;
						line-height: 27px;
						color: #797a8e;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 3; /*超出3行部分显示省略号，去掉该属性 显示全部*/
						-webkit-box-orient: vertical;
					}
				}
				.topic-detail-item-right-bottom {
					height: 150px;
					background: rgba(121, 122, 142, 0.1);
					padding: 0 10px;
					border-radius: 10px;
					width: calc(100% - 20px);
					.topic-detail-item-right-bottom-title {
						width: 100%;
						display: flex;
						justify-content: space-between;
						align-items: center;
						margin-top: 22px;
						.topic-detail-item-right-bottom-title-left {
							font-size: 20px;
							font-family: PingFang SC;
							font-weight: 500;
							line-height: 28px;
							color: #b6b7c3;
						}
						.topic-detail-item-right-bottom-title-left:hover {
							text-decoration: underline;
							cursor: pointer;
						}
						.topic-detail-item-right-bottom-title-right {
							font-size: 18px;
							font-family: DINPro;
							font-weight: 500;
							line-height: 23px;
							color: #797a8e;
							span {
								font-size: 25px;
								font-family: DINPro;
								font-weight: bold;
								line-height: 32px;
								color: #ff3465;
							}
						}
					}
					.topic-detail-item-right-bottom-tags {
						margin-top: 10px;
						display: flex;
						width: 100%;
						justify-content: flex-start;
						align-items: flex-start;
						flex-wrap: wrap;
						.topic-detail-item-right-bottom-tags-item {
							font-size: 13px;
							font-family: PingFang SC;
							font-weight: 500;
							line-height: 18px;
							color: #ff3465;
							padding: 3px 10px;
							background: rgba(255, 52, 101, 0.05);
							border-radius: 5px;
							margin-right: 10px;
						}
					}
					.topic-detail-item-right-bottom-actor {
						margin-top: 10px;
					}
					.topic-detail-item-right-bottom-actor-item {
						font-size: 13px;
						font-family: PingFang SC;
						font-weight: 400;
						color: #797a8e;
						margin-right: 4px;
					}
				}
			}
		}
	}
}
</style>
